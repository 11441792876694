<template id="teamgames">
  <b-container fluid>
    <b-card>
      <b-row align-v="center">
        <b-col cols="1">
          <b-img
            v-if="team"
            :src="
              team.squadra ? team.squadra.logo_url : '/assets/images/team.png'
            "
            height="80"
          />
        </b-col>
        <b-col cols="3">
          <h4 v-if="team">
            {{ team.name }}
          </h4>
        </b-col>
        <b-col cols="2">
          Games: <strong>{{ games.length }}</strong>
        </b-col>
        <b-col>
          <b-row>
            <b-col cols="5">
              <b-input-group prepend="From">
                <b-form-datepicker
                  v-model="filters.from"
                  :dark="true"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    weekday: 'short',
                  }"
                ></b-form-datepicker>
              </b-input-group>
            </b-col>
            <b-col cols="5">
              <b-input-group prepend="To">
                <b-form-datepicker
                  v-model="filters.to"
                  :dark="true"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    weekday: 'short',
                  }"
                ></b-form-datepicker>
              </b-input-group>
            </b-col>
            <b-col cols="1">
              <b-button @click="getTeamGames"
                ><b-icon icon="search"></b-icon
              ></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <div v-if="waiting" class="text-center">
      <img :src="loadingImg" style="width: 150px" />
      <h4>Loading...</h4>
    </div>
    <b-row class="mt-2">
      <b-col v-for="game in games" cols="4" :key="game.id" class="mb-2">
        <router-link
          :to="{
            name: auth.isUserEnable('teams_milan')
              ? 'ScoutingTeamGameMilan'
              : 'ScoutingTeamGame',
            params: { game_id: game.id },
          }"
          target="_blank"
          style="cursor: pointer; text-decoration: none"
        >
          <b-card>
            <b-row align-v="center">
              <b-col cols="8">
                <b-row
                  align-v="center"
                  class="mb-1"
                  :style="
                    game.score_home > game.score_away
                      ? 'font-weight:bold'
                      : 'color:#adb5bd'
                  "
                >
                  <b-col cols="2">
                    <b-img
                      :src="
                        game.team_home.squadra
                          ? game.team_home.squadra.logo_url
                          : '/assets/images/team.png'
                      "
                      height="30"
                    />
                  </b-col>
                  <b-col>
                    {{ game.team_home.name }}
                  </b-col>
                  <b-col cols="1">
                    {{ game.score_home }}
                  </b-col>
                </b-row>
                <b-row
                  align-v="center"
                  :style="
                    game.score_away > game.score_home
                      ? 'font-weight:bold'
                      : 'color:#adb5bd'
                  "
                >
                  <b-col cols="2">
                    <b-img
                      :src="
                        game.team_away.squadra
                          ? game.team_away.squadra.logo_url
                          : '/assets/images/team.png'
                      "
                      height="30"
                    />
                  </b-col>
                  <b-col>
                    {{ game.team_away.name }}
                  </b-col>
                  <b-col cols="1">
                    {{ game.score_away }}
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="text-center">
                <div>{{ game.date | formatDate }}</div>
                <div class="text-muted" style="font-size: 0.7rem">
                  {{ game.league.name }}
                </div>
              </b-col>
            </b-row>
          </b-card>
        </router-link>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from "moment";
import Auth from "@/lib/auth.js";
import loadingImg from "@/assets/images/loading.gif";
export default {
  data: function () {
    return {
      team: null,
      games: [],
      filters: {
        from: this.getInitialFromDate(),
        to: this.getInitialToDate(),
      },
      waiting: false,
      loadingImg,
    };
  },

  components: {},

  created: function () {
    this.getTeam();
    this.getTeamGames();
  },

  watch: {},

  methods: {
    getTeam() {
      this.$http
        .get(
          "/opta/team/" +
            (this.auth.isUserEnable("teams_milan")
              ? "131"
              : this.$route.params.id)
        )
        .then((response) => {
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getTeamGames() {
      this.games = [];
      this.waiting = true;
      this.$http
        .get(
          "/opta/team/games/" +
            (this.auth.isUserEnable("teams_milan")
              ? "131"
              : this.$route.params.id),
          {
            params: this.filters,
          }
        )
        .then((response) => {
          this.games = response.data;
          this.waiting = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getInitialFromDate() {
      let month = moment("08-05", "MM-DD");
      let lastMonth = month < moment() ? month : month.subtract(1, "years");
      return lastMonth.format("YYYY-MM-DD");
    },
    getInitialToDate() {
      return moment().format("YYYY-MM-DD");
    },
  },

  computed: {
    auth: function () {
      return Auth;
    },
  },

  filters: {
    formatDate: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("ddd DD/MM/YYYY");
      }
      return "";
    },
  },
};
</script>
